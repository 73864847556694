.btn {
  $this: &;
  display: inline-block;
  text-align: center;

  border: 4px solid $accent;
  background-color: $accent;

  letter-spacing: em(3);
  text-transform: uppercase;
  font-weight: 600;
  color: $lighten;

  padding: rem(12.5) rem(15);

  min-width: rem(275);

  @include tr(background-color .3s, border-color .3s, color .3s);

  @include hover-focus {
    &:not(#{$this}--no-hover):not(#{$this}--disabled) {
      background-color: $accent-light;
      border-color: $accent-light; } }

  &--disabled {
    cursor: not-allowed; }

  &--full-md {
    @include md {
      min-width: 0;
      width: 100%; } }

  &--full-xs {
    @include xs {
      min-width: 0;
      width: 100%; } }

  &--light {
    background-color: $light;
    border-color: $light;
    color: $accent;
    @include hover-focus {
      &:not(#{$this}--no-hover):not(#{$this}--disabled) {
        background-color: transparent;
        border-color: $light;
        color: $light; } } }

  &--white {
    background-color: $white;
    border-color: $white;
    color: $accent;
    @include hover-focus {
      &:not(#{$this}--no-hover):not(#{$this}--disabled) {
        background-color: darken($white, 10%);
        border-color: darken($white, 10%); } } }

  &--light-dark {
    background-color: $light-dark;
    border-color: $light-dark; }

  &--sec {
    font-size: em(16);
    letter-spacing: em(2.7);
    padding: rem(16) rem(15); }

  &--md {
    font-size: em(16);
    letter-spacing: em(2.7);
    padding: rem(11) rem(15); }

  &--sm {
    font-size: em(16);
    letter-spacing: em(2.7);
    padding: rem(11) rem(15);
    min-width: rem(193); }

  &--xs {
    padding: rem(5.5) rem(35);
    @include xs {
      background-color: transparent;
      border: 0;
      padding: 0;
      min-width: 0;

      text-decoration: underline;
      text-transform: none;
      font-size: em(18, $base-fz-sm);
      font-weight: 300;
      color: inherit;
      letter-spacing: inherit; } }

  &--xxs {
    // font-size: .9em
    letter-spacing: inherit;
    font-weight: 400;
    text-transform: none;
    padding: rem(0) rem(10);
    min-width: rem(100); }

  &--lg {
    min-width: rem(348);
    padding: rem(23.5) rem(35);
    @include sm {
      min-width: rem(210);
      padding: rem(15.5) rem(35); }
    @include xxs {
      min-width: 0;
      width: 100%; } }

  &--xl {
    font-size: 1.5em;
    @include xs {
      font-size: 1em; } }

  &--full {
    min-width: 0;
    width: 100%; }

  &--transp-white {
    background-color: transparent;
    color: $white;
    border-color: $white;

    // +hover-focus
 } }    //   &:not(#{$this}--no-hover):not(#{$this}--disabled)

.btn-file {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  .icon {
    margin-right: 0.5em;
    &--plus {
      fill: none;
      stroke: currentColor;
      path {
        fill: currentColor; } } }

  @include hover-focus {
    text-decoration: underline; } }

.more-btn {
  margin-top: rem(30);

  display: inline-flex;
  align-items: center;
  @include hover-focus {
    text-decoration: underline; }
  &__icon {
    fill: none;
    margin-left: .5em; } }

