.section {
  $this: &;

  padding-top: rem(75) / 2;
  padding-bottom: rem(75) / 2;

  @include sm {
    padding-top: rem(50, $base-fz-sm);
    padding-bottom: rem(50, $base-fz-sm); }

  &:nth-child(2) {
    padding-top: rem(75);
    @include sm {
      padding-top: rem(50, $base-fz-sm); } }
  &:last-child {
    padding-bottom: rem(75);
    @include sm {
      padding-bottom: rem(50, $base-fz-sm); } }

  &__breadcrumbs {
    &--bottom {
      margin-top: rem(30); } }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem(15);

    @include xs {
      display: block; } }

  &__date {
    display: block;
    margin-bottom: rem(20);
    color: $light-dark;

    &--right {
      text-align: right; } }

  &__title {
    text-align: center;
    position: relative;
    margin-bottom: rem(43);

    &:not(#{$this}__title--simple) {
      overflow: hidden;
      margin-left: auto;
      margin-right: auto;
      @include sm {
        margin-left: -$container-padding-sm;
        margin-right: -$container-padding-sm; }

      // For some reason adding just .title doesn' t work.
      .title--h1,
      .title--h1-lg,
      .title--h1-sm,
      .title--h1-xs,
      .title--h2,
      .title--h3,
      .title--h3-lg,
      .title--h4 {
        display: inline-block;
        position: relative;
        z-index: 1;
        padding-left: vw(200);
        padding-right: vw(200);
        max-width: rem(1200);
        @include css-lock(600, 1200, 767, 1399, max-width);

        &::before,
        &::after {
          content: '';
          position: absolute;
          z-index: 1;
          top: 50%;
          transform: translate(0, -50%);

          display: block;
          height: 1px;
          background-color: rgba($accent, .2);
          width: vw(200);

          @include dark-theme {
            background-color: rgba($white, .2); }

          @include xxxlmin {
            width: rem(200); }

          @include sm {
            width: 100px; } }

        &::before {
          right: 100%; }
        &::after {
          left: 100%; } } }

    &[data-animated-el="title"] {
      .title {
        opacity: 0;
        &::before,
        &::after {
          width: 0; } } }

    &--animate {
      .title {
        animation: fade-in-title 1s forwards;
        &::before,
        &::after {
          animation-name: fade-in-title-lines;
          animation-duration: 1s;
          animation-fill-mode: forwards;
          animation-delay: .5s; } } }

    &--full {
      .title {
        max-width: calc(100% - #{vw(400)});
        @include xxxlmin {
          max-width: calc(100% - #{rem(400)}); }
        @include sm {
          max-width: calc(100% - 100px); } } } }

  &__subttl {
    margin-bottom: rem(49);
    @include md {
      margin-bottom: rem(30); } }

  &__block {
    margin-bottom: rem(70);
    @include tr(max-height .7s);
    @include sm {
      margin-bottom: rem(50, $base-fz-sm); }
    @include xs {
      margin-bottom: rem(25, $base-fz-sm); } }

  &__contacts {
    max-width: rem(759);
    margin-left: auto;
    margin-right: auto;

    @include sm {
      &, &--lg {
        max-width: none;
        margin-left: -$container-padding-sm;
        margin-right: -$container-padding-sm; } } }

  &__filters {
    z-index: 9;
    margin-bottom: rem(27);

    &--sticky {
      position: sticky;
      top: $header-height;
      left: 0;
      right: 0;
      animation: filters-slide-down .5s;
      @include tr(transform .5s); }

    &--hidden {
      transform: translate(0, -100%); }

    &--in-container {
      margin-left: -$container-padding;
      margin-right: -$container-padding;
      @include sm {
        margin-left: -$container-padding-sm;
        margin-right: -$container-padding-sm; } } }

  &__btn {
    text-align: center;
    margin-top: rem(85);
    @include sm {
      margin-top: rem(50, $base-fz-sm); } }

  &__video {
    width: 100%;
    object-fit: cover; }

  &__picture {
    display: block;
    font-size: 0;
    text-align: center;

    &--full {
      img {
        width: 100%; } } }

  &__brands {
    max-width: rem(1019);
    margin-left: auto;
    margin-right: auto; }

  &__slider {
    @include windowHeight(#{$header-height} - #{rem(50)} - #{$search-form-height}); // minus slider bottom margin
    @include md {
      @include windowHeight(#{$header-height}); }

    &.slider--section__wrap {
      @include rmin(1800 + 30) { // plus container padding
        height: auto; } }

    .container > & {
      @include r(1800 + 29) { // plus container padding
        margin-left: -$container-padding;
        margin-right: -$container-padding; }
      @include sm {
        margin-left: -$container-padding-sm;
        margin-right: -$container-padding-sm; } } }

  &__search-form {
    @extend .hero__form; }

  &--light {
    background-color: $light; }

  &--light-sm {
    @include sm {
      background-color: $light;
      .article__inner {
        &[data-animated-el="article"] {
          &::before {
            background-color: $light; } } } } } }

[data-animated-section="special-offer"] {
  .slider__wrap {
    opacity: 0; } }

@keyframes fade-in-title {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in-title-lines {
  0% {
    width: 0%; }
  100% {
    width: vw(200); }
  @include xxxlmin {
    0% {
      width: 0%; }
    100% {
      width: rem(200); } }
  @include sm {
    0% {
      width: 0%; }
    100% {
      width: 100px; } } }

@keyframes filters-slide-down {
  0% {
    transform: translate(0, -100%); }
  100% {
    transform: translate(0, 0); } }
