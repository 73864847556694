.lg-next,
.lg-prev {
  display: flex !important;
  align-items: center;
  justify-content: center;

  height: 50%;
  transform: translate(0, -50%) !important;
  background-color: transparent !important;
  .icon {
    font-size: em(32); }

  &::before,
  &::after {
    content: '' !important; } }

.lg-next {
  .icon {
    transform: scaleX(-1); } }

.lg-outer .lg-thumb {
  margin-left: auto;
  margin-right: auto; }
