.input {
  $this: &;

  position: relative;
  @include sm {
    font-size: em(18, $base-fz-sm); }

  #{$text-inputs} {
    width: 100%;
    height: rem(48);

    border-bottom: 1px solid $accent;
    background-color: transparent;

    text-align: center;
    padding: .3em 0;

    @include sm {
      height: rem(48, $base-fz-sm); }

    @include tr(background-color .3s);
    @include placeholder {
      color: inherit; }

    &:focus {
      border-color: $accent-light; } }

  textarea {
    min-height: rem(64);
    padding-top: rem(20);
    @include sm {
      min-height: rem(64, $base-fz-sm);
      padding-top: rem(20, $base-fz-sm); } }

  &__label {
    display: block;
    text-align: center;
    font-size: em(13);

    position: absolute;
    left: 0;
    right: 0;
    top: -.5em;
    pointer-events: none;
    &--error {
      color: $danger;
 } }      // display: none

  &--select {
    position: relative;

    select {
      appearance: none;
      font-size: inherit;
      font-weight: inherit;
      font-family: inherit;
      color: inherit;
      border-radius: 0;

      width: 100%;
      height: rem(56);

      border: 0;
      border-bottom: 1px solid $accent;
      background-color: transparent;

      text-align: center;
      padding: .3em 0;
      padding-right: rem(60);

      @include sm {
        height: rem(48, $base-fz-sm); }

      @include tr(border-color .3s);
      @include placeholder {
        color: inherit; }

      &:focus {
        border-color: $accent-light; } }

    &:not(#{$this}--xxs) {
      &::after,
      &::before {
        content: '';
        position: absolute;
        pointer-events: none; }

      &::after {
        z-index: 0;
        top: 0;
        right: 0;
        height: 100%;
        width: rem(56);
        background-color: $accent; }

      &::before {
        @include icon-arrow(1, 1, .2, $white, -45);
        z-index: 1;
        top: rem(15);
        right: rem(20);

        @include sm {
          top: rem(13);
          right: rem(18); } } } }

  &--xxs {
    #{$text-inputs},
    select {
      background-color: $white;
      text-align: left;
      padding: rem(5) rem(5);
      height: rem(31);
      width: rem(100); } }

  &--error {
    #{$text-inputs} {
      border-color: $danger;
      &:focus {
        border-color: $danger; } }

    #{$this}__label--error {
      display: block; } }

  &--simple {
    font-size: 14px;

    .has-error {
      #{$text-inputs} {
        border-color: $danger; } }

    #{$text-inputs} {
      background-color: $white;
      border: 1px solid $white;
      height: 30px;
      padding: .3em 1em;
      text-align: left; } }

  &--disabled {
    opacity: 0.5; } }

.file-input {
  $this: &;

  position: relative;
  width: rem(50);
  height: rem(50);
  display: block;
  background-color: $light-dark;

  @include hover {
    #{$this}__close {
      @include visible; } }

  &__input {
    position: absolute;
    opacity: 0;
    visibility: hidden; }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

  &__close {
    @include icon-close(1rem, .1rem, currentColor);
    position: absolute;
    top: -.5rem;
    right: -.5rem;

    @include tr(visibility .3s, opacity .3s, color .3s);

    @include hover {
      color: $accent-light; }

    .no-touch & {
      @include hidden; } } }
