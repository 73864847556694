strong, b {
  font-weight: 600; }

h1, h2, h3, h4, h5, h6 {
  font-weight: inherit; }

.title {
  display: block;
  line-height: 1.1;

  &--h1 {
    @include css-lock(40, 60, 413, 1399, font-size);
    font-weight: 600;
    text-transform: uppercase;
    @include xxxs {
      font-size: em(36, $base-fz-sm); }
    @include r(374) {
      font-size: em(30, $base-fz-sm); } }

  &--h1-lg {
    @include css-lock(50, 139, 413, 1399, font-size);
    font-weight: 600;
    text-transform: uppercase;
    line-height: .9; }

  &--h1-sm {
    @extend  .title--h1; }

  &--h1-xs {
    @include css-lock(40, 64, 413, 1399, font-size);
    font-weight: 600;
    text-transform: uppercase; }

  &--h1-xxs {
    @include css-lock(28, 36, 413, 1399, font-size);
    font-weight: 600;
    text-transform: uppercase; }

  &--h2 {
    @include css-lock(32, 50, 413, 1399, font-size);
    font-weight: 600;

    @include xxxs {
      font-size: em(24, $base-fz-sm); } }

  &--h3 {
    font-size: em(32);
    @include sm {
      font-size: em(25, $base-fz-sm); } }

  &--h3-lg {
    @include css-lock(28, 36, 413, 1399, font-size);
    font-weight: 600; }

  &--h4 {
    font-size: 1em;
    @include sm {
      font-size: em($base-fz, $base-fz-sm); } }

  &--ttn {
    text-transform: none; }

  &--600 {
    font-weight: 600; } }

.big {
  font-size: em(25);
  font-weight: 600;
  @include sm {
    font-size: em(20); } }

.price {
  font-weight: 600;
  font-size: em(25);
  line-height: 1;

  @include sm {
    font-size: em(20); }

  &--lg {
    font-size: em(72);
    @include sm {
      font-size: em(72, $base-fz-sm); }
    @include xxxs {
      font-size: em(56, $base-fz-sm); } }

  &--md {
    font-size: em(36);
    @include md {
      font-size: em(32); }
    @include sm {
      font-size: em(26, $base-fz-sm); } }

  &--sm {
    font-size: 1em; }

  &--old {
    text-decoration: line-through; }

  &--old-danger {
    position: relative;
    display: inline-block;
    @include before {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      background-color: $danger;
      height: 2px; } } }

.list {
  li {
    &::before {
      content: '-';
      display: inline-block;
      margin-right: .3em; } }

  &--bold {
    font-weight: 600;
    li {
      &::before {
        display: none; } } } }
