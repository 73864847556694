.services-list {
  @extend %row;
  @include counter('services', '');
  justify-content: center;
  padding-top: rem(103);

  @include xl {
    padding-top: rem(80); }

  @include md {
    padding-top: rem(60); }

  &__item {
    $gap: rem(158);
    $gap-vert: rem(130);

    --gap: #{$gap};
    --gap-vert: #{$gap-vert};

    width: calc(50% - #{$gap / 2});
    width: calc(50% - (var(--gap) / 2));
    position: relative;
    margin-bottom: $gap-vert;
    margin-bottom: var(--gap-vert);

    @include xxl {
      --gap: #{rem(80)};
      --gap-vert: var(--gap); }

    @include lg {
      --gap: #{rem(30)}; }

    @include md {
      width: 100%;
      margin-left: 0 !important;
      margin-right: 0 !important; }

    &::before {
      position: absolute;
      z-index: 0;
      top: -.2em;
      left: -.3em;

      @include css-lock(80, 365, 413, 1399, font-size);
      font-weight: 600;
      line-height: .7; }

    &:nth-child(even) {
      margin-left: $gap / 2;
      margin-left: calc(var(--gap) / 2); }

    &:nth-child(odd) {
      margin-right: $gap / 2;
      margin-right: calc(var(--gap) / 2); } } }

.services-list-item {
  &__inner {
    background-color: $black-light;
    box-shadow: 0px 24px 104px #000000;
    padding: rem(60);
    height: 100%;

    position: relative;
    z-index: 1;

    @include xl {
      padding: rem(40); }

    @include lg {
      padding: rem(30); }

    @include md {
      padding: rem(20); } }

  &__title {
    @extend .title;
    font-weight: 600;
    font-size: em(36);
    margin-bottom: rem(20); } }
