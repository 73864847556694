.service-item {
  $this: &;

  display: block;
  padding: percentage(393 / 450) rem(30) rem(30);
  position: relative;
  text-align: center;
  height: 100%;
  overflow: hidden;

  @include hover-focus {
    &:not(#{$this}--combine) {
      #{$this}__img {
        transform: scale(1.2);
        @include before {
          @include visible; } } } }

  @include xl {
    padding: percentage(393 / 450) rem(15) rem(15); }

  @include xs {
    padding: 0; }

  &__img {
    display: block;
    @include coverdiv;
    @include cover;
    z-index: 0;
    background-color: $light;
    @include tr(transform .7s);
    @include before {
      @include coverdiv;
      background-color: rgba($black, .2);
      @include hidden;
      @include tr(opacity .3s, visibility .3s); }

    @include xs {
      position: relative;
      margin-bottom: rem(29, $base-fz-sm);
      padding-top: 100%; } }

  &__title {
    @extend %service-title;

    display: block;
    text-align: center;
    width: 100%;
    position: relative;
    z-index: 1;

    color: $white;

    @include xs {
      color: inherit; } }

  &__content {
    display: block; }


  &__content-title {
    @extend .title;
    font-size: em(25);
    font-weight: 600;
    margin-bottom: rem(15);
    @include sm {
      font-size: em(25, $base-fz-sm); } }

  &__label {
    display: block;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1;
    text-transform: uppercase;
    text-align: center;

    &--available {
      @include css-lock(30, 72, 413, 1399, font-size);
      opacity: .6;

      top: 45%;
      transform: translate(-50%, -50%) rotate(-1.69deg);
      white-space: nowrap; }

    &--percent {
      top: 30%;

      .icon {
        @include css-lock(80, 125, 413, 1399, font-size);
        opacity: .3;
        color: $light; } }

    &--order {
      @include css-lock(30, 72, 413, 1399, font-size);
      top: 35%;
      color: #A8A999;
      opacity: .35;
      transform: translate(-50%, -50%) rotate(2.82deg); } }

  &--combine {
    padding: 0;
    text-align: left;

    #{$this}__img {
      position: relative;
      padding-top: 100%;
      @include xs {
        max-width: percentage(340 / 373);
        padding-top: percentage(340 / 373);
        margin-left: auto;
        margin-right: auto; } }

    #{$this}__content {
      padding: rem(64) rem(30) rem(34);
      @include xs {
        padding: rem(29) 0 rem(29); } }

    #{$this}__title {
      margin-top: rem(-50);
      @include xs {
        margin-top: 0; } } }

  &--full {
    padding: percentage(407 / 919) rem(20) rem(20);
    @include xs {
      padding: percentage(330 / 373) rem(20) rem(20);
      #{$this}__img {
        position: absolute;
        padding: 0;
        margin: 0; } }

    #{$this}__label {
      text-align: left;
      top: rem(10);
      left: rem(10);
      transform: none; } } }
