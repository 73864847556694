.header {
  display: flex;
  align-items: center;

  background-color: $white;

  padding: rem(10) 0  rem(10) rem(54);

  @include dark-theme {
    background-color: $black; }

  @include r(1849) {
    padding: rem(10) 0 rem(10) $container-padding; }

  @include xl {
    padding: rem(10) $container-padding; }

  @include sm {
    padding: rem(10, $base-fz-sm) $container-padding-sm; }

  &__body {
    flex-grow: 1;
    @include xl {
      display: block;
      @include overlay;
      top: $header-height;
      z-index: 10;
      background-color: $light;

      overflow: hidden auto;
      padding: rem(35);

      @include dark-theme {
        background-color: $black; }

      @include vertical;
      @include hidden;
      @include ready {
        @include tr(opacity .4s, visibility .4s); }

      &--open {
        @include visible; } }

    @include sm {
      padding: rem(35, $base-fz-sm); }
    @include xxxs {
      padding: rem(20, $base-fz-sm); } }

  &__body-in {
    display: flex;
    align-items: center;
    @include xl {
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      font-size: 1rem; }

    @include xs {
      text-align: left; } }

  &__nav {
    flex-grow: 1; }

  &__logo {
    margin-right: vw(100);
    @include r(1849) {
      margin-right: rem(50); } }

  &__controls {
    display: flex;
    align-items: center;

    padding: rem(13) rem(30);

    background-color: $light;

    @include dark-theme {
      background-color: $black; }

    @include r(1849) {
      margin-left: rem(15);
      padding: rem(13) rem(10); }

    @include xl {
      display: block;
      margin-left: 0;
      padding: 0; } }

  &__phone {
    font-weight: 600;
    font-size: em(13);

    padding-right: rem(30);

    @include r(1849) {
      padding-right: rem(20); }

    @include xl {
      font-size: em(22); }

    @include xxxs {
      font-size: em(18, $base-fz-sm); }

    @include tr(color .3s);
    @include hover-focus {
      text-decoration: underline; } }

  &__lang {
    border-left: 1px solid #E5E5E5;
    padding-left: rem(23);
    @include r(1849) {
      padding-left: rem(13); }
    @include xl {
      display: none !important;
      padding-left: 0;
      border-left: 0; }

    &--mob {
      margin-bottom: rem(20);
      margin-left: rem(-7);
      margin-right: rem(-7);
      @include xlmin {
        display: none !important; } } }

  &__burger {
    margin-left: auto;
    @include xlmin {
      display: none !important; } } }


.lang {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: em(13);

  padding-top: rem(10);
  padding-bottom: rem(10);

  @include xl {
    font-size: em(22);
    justify-content: center; }
  @include xs {
    justify-content: flex-start; }

  &__item {
    margin-left: rem(7);
    margin-right: rem(7);
    &--current {
      text-decoration: underline; } }

  &__link {
    @include hover-focus {
      text-decoration: underline; } } }


.burger {
  $size: 26px;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;

  position: relative;
  overflow: hidden;

  height: $size;

  @include tr(color .3s);
  @include hover-focus {
    color: $accent-light; }

  &::before,
  &::after {
    content: '';
    display: block;

    width: $size;
    height: 2px;
    background-color: currentColor;

    @include tr(transform .4s $easeOutExpo); }

  &::before {
    margin-bottom: rem(5); }

  &::after {
    transform: translate(50%, 0); }

  &--active {
    &::before {
      transform: rotate(45deg) translate(#{rem(2)}, #{rem(2)}); }
    &::after {
      transform: rotate(-45deg) translate(#{rem(3)}, #{rem(-3)}); } } }
