.search-form {
  color: $c-text;
  background-color: $white;

  @include dark-theme {
    background-color: $black;
    color: $white; }

  > .container {
    height: 100%; }

  &__title {
    font-weight: 600;
    @include xl {
      width: 100%;
      text-align: center;
      margin-bottom: rem(15); } }

  &__select {
    width: rem(300);

    @include xl {
      width: calc(50% - #{$container-padding * 2});
      margin-left: $container-padding;
      margin-right: $container-padding;
      margin-bottom: rem(30); }

    @include md {
      margin-left: 0;
      margin-right: 0;
      width: 100%; } }

  &__form {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    padding-top: rem(15);
    padding-bottom: rem(15);

    @include xl {
      flex-wrap: wrap;
      margin-left: -$container-padding;
      margin-right: -$container-padding; }

    @include md {
      flex-direction: column;
      margin-left: 0;
      margin-right: 0; }

    .input,
    .btn {
      flex-shrink: 0; }

    .btn {
      @include xl {
        width: 100%;
        max-width: rem(300);
        margin-left: auto;
        margin-right: auto; } } } }
