.brands {
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: 25%;
    @include xs {
      width: 50%; } } }

.brand {
  $this: &;

  @extend %flex-center;
  flex-direction: column;
  text-align: center;
  padding: rem(30);

  @include lg {
    padding: rem(15); }

  &:hover {
    #{$this}__img {
      transform: translate(-50%, -50%) scale(1.1); } }

  &__img-wrap {
    display: block;

    height: rem(86);
    width: rem(86);

    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem(10);

    position: relative; }

  &__img {
    @include vcenter;
    width: 100%;
    max-height: 100%;
    object-fit: contain;
    @include tr(transform .3s); } }
