html,
body {
  height: 100%; }

html {
  font-size: $base-fz + px;
  font-weight: 300;
  line-height: (24 / 18);
  color: $c-text;
  @include reg;

  &.dark-theme {
    color: $white; }

  @include sm {
    font-size: $base-fz-sm + px; } }

body {
  background-color: $white;
  min-width: 320px;
  @include no-scroll;
  @include dark-theme {
    background-color: $black; } }

.icon {
  display: inline-block;
  fill: currentColor;
  font-size: inherit; }

.lazy {
  $this: &;
  &--loaded:not(#{$this}--no-fade-in) {
    animation: fadeIn 1s; } }

.main {
  &--dark {
    background-position: 100% 0%;
    background-repeat: no-repeat;
    background-size: contain; } }


.container {
  padding-left: $container-padding;
  padding-right: $container-padding;
  margin: 0 auto;
  width: 100%;

  @include sm {
    padding-left: $container-padding-sm;
    padding-right: $container-padding-sm; }

  &--fluid {
    max-width: none; }

  &--md {
    max-width: rem(1380) + rem(30); }

  &--sm {
    max-width: rem(933) + rem(30); }

  &--sm-lg {
    max-width: rem(1200) + rem(30); }

  &--lg {
    max-width: rem(1800) + rem(30); }

  &--xl {
    @include lgmin {
      max-width: calc(100% - #{rem(90)}); } } }

.logo {
  display: inline-block;
  font-size: 0;
  img {
    width: 189px; }

  .icon {
    font-size: rem(34);
    @include sm {
      font-size: rem(22, $base-fz-sm); } }

  &--sm {
    img {
      width: 169px; } }

  &--lg {
    img {
      width: 280px; } } }

[data-animated-el="stagger"] {
  overflow: hidden;
  opacity: 0; }

.date {
  color: $light-dark; }

.close {
  @include icon-close(1.8em, .2em, $light);

  color: $white; }

.aut-trade-features {
  @extend %row;
  margin-left: rem(-15);
  margin-right: rem(-15);
  li {
    width: 50%;
    padding-left: rem(15);
    padding-right: rem(15);
    margin-bottom: rem(50);
    @include md {
      margin-bottom: rem(30); }

    @include xs {
      width: 100%; } } }

.youtube-video {
  border: 0;
  background-color: #ccc;
  display: block;
  font-size: 0;
  padding: 0;
  margin-bottom: 1rem;

  position: relative;
  cursor: pointer;
  img {
    width: 100%; }

  &::before {
    height: 0;
    width: 0;
    top: 50%;
    left: 50%;
    z-index: 1;
    content: "";
    position: absolute;
    border-left: 35px solid #fff;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    transform: translate(-50%, -50%); }

  &::after {
    top: 50%;
    left: 50%;
    width: 100px;
    height: 65px;
    position: absolute;
    display: inline-block;
    background-color: rgba(0,0,0,0.7);
    transform: translate(-50%, -50%);
    transition: opacity .2s;
    content: ""; } }
