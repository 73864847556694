.scroll-to-top {
  @extend %flex-center;

  color: $white;

  border: 2px solid currentColor;
  width: 36px;
  height: 36px;

  @include before {
    @include icon-arrow(15, 15, 2, currentColor, 135, px);
    display: block;
    margin-top: .5em; } }

.scroll-down {
  @include tr(color .3s);
  @include hover-focus {
    color: $accent-light; }

  &__icon {
    fill: none;
    stroke: currentColor;
    stroke-width: 2; } }
