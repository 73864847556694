.contacts {
  $this: &;

  position: relative;
  overflow: hidden;

  &__form {
    $gap: (rem(759) - rem(650)) / 2;

    margin-left: -$gap;
    margin-right: -$gap;

    @include sm {
      margin-left: 0;
      margin-right: 0; } }

  &__inner {
    max-width: rem(650);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    @include before {
      @include coverdiv;
      z-index: 0;
      background-color: $light;

      @include tr(transform 2.5s $easeOutQuad); }

    @include dark-theme {
      @include before {
        display: none; } }

    @include sm {
      max-width: none;
      padding-left: $container-padding-sm;
      padding-right: $container-padding-sm;
      padding-bottom: rem(40, $base-fz-sm); } }


  &__block {
    position: relative;
    z-index: 1;

    padding-top: rem(56 / 2);
    padding-bottom: rem(56 / 2);
    &:first-child {
      padding-top: rem(56); } }

  &__block-title {
    text-align: center;
    font-weight: 600;
    margin-bottom: rem(29); }

  &--md {
    .contacts-map {
      text-align: center;
      &__top {
        display: block; }

      &__tab {
        @include css-lock(18, 24, 413, 1399, font-size);

        @include notlast {
          &::after {
            content: '/';
            display: inline-block;
            margin-left: .5em; } } }

      &__controls {
        margin-bottom: rem(15);
        position: relative; }

      &__title {
        position: absolute;
        left: 0;
        right: 0;
        top: rem(180);
        z-index: 3;
        background-image: linear-gradient(88.72deg, rgba(39, 39, 39, 0.5) 0.56%, rgba(52, 52, 52, 0.5) 99.93%);

        font-size: em(24);
        padding-top: rem(10);
        padding-bottom: rem(10);

        @include xs {
          position: static;
          top: auto; } } } }


  &--lg {
    #{$this}__inner {
      max-width: rem(1315);
      margin-left: auto;
      margin-right: auto; }

    #{$this}__form {
      margin-left: auto;
      margin-right: auto;
      max-width: rem(759); } }

  &--animate {
    #{$this}__inner {
      @include before {
        transform: translate(0, -100%); } } } }


.contacts-form {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include xs {
    display: block; }

  &__title-block {
    width: 45%;
    @include xs {
      width: auto;
      margin-bottom: rem(53, $base-fz-sm); } }

  &__title {
    display: block;
    font-size: em(25);
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: rem(25); } }

.contacts-map {
  $this: &;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-left: 0;
    padding-right: 0;
    padding-bottom: rem(28);

    @include sm {
      font-size: em(18, $base-fz-sm); }

    @include xs {
      display: block;
      text-align: center;
      padding-left: 0;
      padding-right: 0; } }

  &__title {
    display: none;
    font-weight: 600;
    &--active {
      display: block; } }

  &__controls {
    text-align: left;
    @include xs {
      display: inline-block;
      margin-bottom: rem(3, $base-fz-sm); } }

  &__tab {
    font-weight: 600;
    opacity: .5;
    margin-bottom: rem(15);
    @include hover {
      @extend #{$this}__tab--active; }

    @include notlast {
      margin-right: rem(24); }

    &--active {
      opacity: 1;
      text-decoration: underline; } }

  &__map {
    display: none;
    position: relative;
    padding-top: percentage(266 / 650);
    iframe {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

    &--active {
      display: block; } }

  &--lg {
    #{$this}__top {
      @include sm {
        display: none; } }

    #{$this}__top-mob {
      @extend #{$this}__top;
      @include smmin {
        display: none; }
      @include sm {
        display: flex; }
      @include xs {
        display: block; } }

    #{$this}__title {
      display: block;
      @include sm {
        display: none;
        &--active {
          display: block; } } }

    #{$this}__top-col {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 50%;

      @include sm {
        display: none; }

      &:nth-child(odd) {
        padding-right: rem(45); }
      &:nth-child(even) {
        padding-left: rem(45); } }

    #{$this}__map-wrap {
      @extend %row; }

    #{$this}__map {
      display: block;
      width: calc(50% - #{rem(15 / 2)});
      padding-top: percentage(133 / 650);

      @include md {
        padding-top: percentage(266 / 650); }

      @include sm {
        display: none;
        width: 100%;
        margin: 0 !important;
        // padding-top: 60%

        &--active {
          display: block; } }


      &:nth-child(odd) {
        margin-right: rem(15) / 2; }
      &:nth-child(even) {
        margin-left: rem(15) / 2; } } } }

.contacts-map-dropdown {
  $this: &;

  font-weight: 600;
  position: relative;
  &__opener {
    display: flex;
    align-items: center;
    position: relative;
    padding: rem(10) rem(25) rem(10) 0;

    cursor: pointer;

    @include after {
      @include icon-arrow(.5, .5, .1, currentColor, -45, em);
      display: block;
      position: absolute;
      top: 45%;
      right: 0;
      transform: translate(0, -50%) rotate(-45deg); } }

  &__panel {
    position: absolute;
    z-index: 3;
    top: 100%;
    left: 0;
    text-align: left;
    @include hidden;
    @include ready {
      @include tr(opacity .5s, visibility .5s); } }

  &__option {
    padding-right: rem(10);
    cursor: pointer;
    &--selected {
      text-decoration: underline; } }


  &--open {
    #{$this}__panel {
      background-color: $light-dark; }
    #{$this}__panel {
      @include visible; } } }
