$base-fz:    18;
$base-fz-sm: 16;

$text-inputs: "[type='color']", "[type='date']", "[type='datetime']", "[type='datetime-local']", "[type='email']", "[type='month']", "[type='number']", "[type='password']", "[type='search']", "[type='tel']", "[type='text']", "[type='time']", "[type='url']", "[type='week']", "input:not([type])", "textarea";
$check-inputs: "[type='checkbox']", "[type='radio']";


$accent:       #0C2340;
$accent-light: #49648a;
$dark:         #1B1F2F;
$light-dark:   #9DA6B1;
$light:        #F4F4F4;
$lighten:      #F2F1F0;
$danger:       #D11212;
$danger-dark:  #BB1D2B;

$black:        #000;
$black-light: #141414;
$white:        #fff;

$c-text: $accent;

$header-height: 64px;

$container-padding:    rem(15);
$container-padding-sm: rem(20, $base-fz-sm);

$search-form-height: 15vh;

%row {
  display: flex;
  flex-wrap: wrap; }

%flex-center {
  display: flex;
  align-items: center;
  justify-content: center; }

%service-title {
  text-transform: uppercase;
  font-size: em(30);
  line-height: 1;
  font-weight: 600; }

%overlay {
  @include overlay; }

@mixin windowHeight($offset: 0px) {
  height: calc(100vh - #{$offset});
  height: calc((var(--vh, 1vh) * 100) - #{$offset}); }
