.banner {
  $this: &;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  position: relative;
  min-height: vw(684);
  color: $light;

  padding: rem(75) rem(65);

  @include xl {
    padding: rem(60) rem(50); }

  @include lg {
    padding: rem(45); }

  @include md {
    min-height: vw(500, 991); }

  @include sm {
    padding: rem(45) rem(30) rem(30); }

  @include xs {
    min-height: 0; }


  &__caption {
    position: relative;
    z-index: 1;
    @include xs {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      min-height: vw(363 - 75, 414); } } // minus padding

  &__picture {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    img,
    source {
      @extend #{$this}__picture; } }

  &__title {
    @extend .title;
    @extend .title--h1;
    text-transform: none;
    margin-bottom: rem(15);
    @include sm {
      display: block; }

    @include xxxs {
      font-size: em(36, $base-fz-sm); } } }

