@mixin scrollbar($background: $white, $thumb-bg: $accent, $radius: rem(10)) {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  &::-webkit-scrollbar-track {
    background-color: $background;
    outline: none;
    border-radius: $radius; }
  &::-webkit-scrollbar-thumb {
    background-color: $thumb-bg;
    border-radius: $radius;
    @include tr(background-color .3s); } }

.scrollbar {
  position: relative;
  overflow: auto;
  @include scrollbar;

  &--sm {
    @include smmin {
      overflow: visible; } }

  &--light {
    @include scrollbar($background: $accent-light, $thumb-bg: $white);
    .simplebar-track {
      background-color: $accent-light; }
    .simplebar-scrollbar {
      &::before {
        background-color: $white; } } } }

.simplebar-scrollbar {
  z-index: 1;
  &::before {
    opacity: 1 !important;
    background-color: $accent;
    border-radius: rem(10);
    width: 100%;
    right: 0;
    left: 0;
    z-index: 1; } }

.simplebar-track {
  border-radius: rem(10);
  background-color: $white; }

.simplebar-track.simplebar-horizontal {
  height: 5px;
  .simplebar-scrollbar {
    top: 0;
    height: 100%;
    @include before {
      left: 0;
      right: 0; } } }

