.s-about {
  padding-bottom: rem(52);
  &:nth-child(2) {
    padding-top: rem(92); } }

.s-automobile {
  padding-bottom: rem(85); }

.s-contacts {
  @include sm {
    padding-bottom: 0;
    &:last-child {
      padding-bottom: 0; } } }

.s-insurance {
  &__article-inner {
    @include xs {
      display: flex;
      flex-direction: column;
      img, p {
        flex-shrink: 0; }
      img {
        order: 100;
        margin-top: rem(30, $base-fz-sm);
        margin-bottom: 0 !important; } } } }

.s-about-services {
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: contain; }

.s-sourcemap {
  &__nav {
    margin-bottom: rem(85);

    @include md {
      margin-bottom: rem(50); }

    .nav__list {
      justify-content: center; } }

  &__block {
    margin-bottom: rem(40);
    @include md {
      margin-bottom: rem(20); } } }

.s-fin-features {
  background-color: $light;

  &__img {
    max-width: percentage((2300 - 1350) / 2300);
    @include xs {
      width: 100%;
      max-width: none;
      margin-bottom: rem(30, $base-fz-sm); } } }

.s-aut-trade-features {
  &__top {
    background-color: $light;
    padding: rem(30) 0; }

  &__bottom {
    padding: rem(30) 0 0; }

  &__top-inner {
    @include clr; }

  &__top-img {
    float: right;
    max-width: 50%;
    margin-left: rem(30);
    margin-bottom: rem(30);
    @include xs {
      width: 100%;
      max-width: none;
      float: none;
      margin-left: 0; } }


  &__top-content {

    .title, p {
      @include notlast {
        margin-bottom: rem(30); } } } }
