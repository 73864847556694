.sourcemap {
  @extend %row;
  justify-content: center;
  @include xs {
    text-align: center; }

  &__item {
    display: flex;
    flex-direction: column;

    width: percentage(1 / 3);
    margin-bottom: rem(62);

    @include md {
      width: 50%;
      display: block;
      margin-bottom: rem(30); }

    @include xs {
      width: 100%; }

    &:nth-child(2) {
      align-items: center; }

    &:nth-child(3) {
      align-items: flex-end; } } }


.sourcemap-block {
  $this: &;

  &__title {
    margin-bottom: rem(20); }

  &--lg {
    #{$this}__title {
      margin-bottom: rem(72);
      text-align: center;
      @include lg {
        margin-bottom: rem(50); }

      @include md {
        margin-bottom: rem(30); } } } }
