.prev,
.next {
  @extend %flex-center;
  @include before {
    @include icon-arrow(1.8, 1.8, .1, currentColor, 45, em);
    display: block; } }

.next {
  transform: scaleX(-1); }

.slider {
  @mixin disabled-slide {
    transform: scale(.9);
    opacity: .4;
    @include tr(opacity .4s, transform .4s); }

  @mixin active-slide {
    transform: scale(1);
    opacity: 1; }

  &__wrap {
    position: relative;
    * {
      box-sizing: border-box; }

    &:not(.slider__wrap--initialized) {
      .slider__controls {
        display: none; } } }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: absolute;
    z-index: 2;
    top: calc(50% - #{rem(79) / 2}); // minus half pagination height
    left: 0;
    right: 0;
    height: 0;
    transform: translate(0, -50%);

    padding-left: rem(20);
    padding-right: rem(20);

    @include xxl {
      display: none; } }

  &__prev,
  &__next {
    &.swiper-button-disabled {
     @include hidden; } }

  &__pagination {
    text-align: center;
    margin-top: rem(56);

    @include sm {
      margin-top: rem(30, $base-fz-sm); }

    .swiper-pagination-bullet {
      display: inline-block;
      vertical-align: middle;

      width: rem(12);
      height: rem(12);
      border-radius: 50%;

      margin-left: rem(25);
      margin-right: rem(25);

      background-color: $lighten;
      opacity: 1;
      cursor: pointer;

      @include dark-theme {
        background-color: #373737; }

      @include sm {
        margin-left: rem(15, $base-fz-sm);
        margin-right: rem(15, $base-fz-sm); }

      &.swiper-pagination-bullet-active {
        background-color: $accent;
        @include dark-theme {
          background-color: $white; } } }

    &.swiper-pagination-bullets-dynamic {
      white-space: nowrap;
      margin-left: auto;
      margin-right: auto;
      .swiper-pagination-bullet {
        @include tr(transform 200ms, left 200ms); } } }

  &--services {
    &__wrap {
      @include xs {
        margin-left: -$container-padding-sm;
        margin-right: -$container-padding-sm; }

      .slider {
        &__slide {
          height: auto;
          @include xs {
            width: percentage(340 / 414);
            @include disabled-slide;
            &.swiper-slide-active {
              @include active-slide; } } }

        &__pagination {
          @include mdmin {
            display: none; } } } } }

  &--hero {
    &__wrap {
      color: $white;
      @include mdmin {
        margin-bottom: rem(50); }

      .swiper-slide {
        @include mdmin {
          @include windowHeight(#{$header-height} - #{rem(50)} - #{$search-form-height}); } // minus slider bottom margin

        @include md-port {
          @include windowHeight(#{$header-height}); } }

      .slider {
        &__pagination {
          @extend .container;
          @extend .container--xl;

          padding-left: 0;
          padding-right: 0;

          position: absolute;
          z-index: 2;
          bottom: rem(-50);

          left: 50%;
          transform: translate(-50%, 0);

          @include md-port {
            bottom: calc(50% + #{rem(30)}); }

          @include md-land {
            bottom: auto;
            top: calc(100vh - #{$header-height} - #{rem(30)}); } } } } }

  &--section {
    &__wrap {
      .swiper-slide {
        @include rmin(1800 + 29) { // plus container padding
          min-height: rem(750);
          height: auto; }
        // +mdmin
        //   height: auto

        // +xl
        //   min-height: rem(600)

        // +lg
        //   min-height: rem(500)

        // +md
 } } }        //   min-height: 0

  &--model {
    &__wrap {
      @include sm {
        background-color: $light;
        padding-top: rem(50, $base-fz-sm);
        padding-bottom: rem(50, $base-fz-sm);

        margin-left: -$container-padding-sm;
        margin-right: -$container-padding-sm; }
      .slider {
        &__slide {
          @include sm {
            width: percentage(340 / 414);
            @include disabled-slide;
            &.swiper-slide-active {
              @include active-slide; } } }
        &__prev,
        &__next {
          position: absolute;
          z-index: 1;
          top: 50%;
          transform: translate(0, -50%); }

        &__prev {
          left: rem(-35); }

        &__next {
          right: rem(-35);
          transform: translate(0, -50%) scaleX(-1); }

        &__pagination {
          @include sm {
            .swiper-pagination-bullet {
              background-color: $white;
              &.swiper-pagination-bullet-active {
                background-color: $accent; } } } } } } }

  &--blog {
    &__wrap {
      @include tr(padding .3s);
      padding-left: $container-padding;
      @include lg {
        padding-right: $container-padding; }
      @include sm {
        padding-left: $container-padding-sm;
        padding-right: $container-padding-sm; }

      .swiper-container {
        &::before,
        &::after {
          content: '';
          position: absolute;
          z-index: 2;
          top: 0;
          bottom: 0;

          width: 15%;
          background-image: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
          @include tr(opacity .3s, visibility .3s);
          @include dark-theme {
            background-image: linear-gradient(270deg, #000000 0%, rgba(58, 58, 58, 0) 100%); }
          @include lg {
            display: none; } }

        &::after {
          right: 0; }

        &::before {
          left: 0;
          background-image: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
          @include dark-theme {
            background-image: linear-gradient(90deg, #000000 0%, rgba(58, 58, 58, 0) 100%); }
          @include hidden; } }

      &.show-center-part {
        padding-left: 0;
        padding-right: 0;
        .swiper-container {
          &::before {
            @include visible; } } }

      &.has-reached-end {
        padding-right: $container-padding;
        padding-left: 0;
        .swiper-container {
          &::after {
            @include hidden; }
          &::before {
            @include visible; } } }

      .slider {
        &__slide {
          width: 29%;
          height: auto;
          @include lg {
            width: auto; }
          @include sm {
            width: percentage(340 / 414);
            @include disabled-slide;
            &.swiper-slide-active {
              @include active-slide; } } }

        &__controls {
          @include xl {
            display: flex; }
          @include lg {
            display: none; } } } } }

  &--fin-services {
    &__wrap {
      @include xs {
        background-color: $light;
        margin-left: -$container-padding-sm;
        margin-right: -$container-padding-sm;

        padding: rem(50, $base-fz-sm) 0; }

      .slider {
        &__pagination {
          .swiper-pagination-bullet {
            background-color: $white;
            &.swiper-pagination-bullet-active {
              background-color: $accent; } }

          @include xsmin {
            display: none; } }

        &__slide {
          @include sm {
            width: 100%;
            padding-left: $container-padding-sm;
            padding-right: $container-padding-sm;

            .service-item__img {
              transform: scale(1.4, .9);
              opacity: .4;
              @include tr(opacity .4s, transform .4s); }
            &.swiper-slide-active {
              .service-item__img {
                transform: scale(1);
                opacity: 1; } } } } } } }

  &--gallery {
    &__wrap {
      .swiper-container,
      .slider {
        height: 100%;
        @include xs {
          padding-bottom: rem(20, $base-fz-sm); } }

      .slider {
        &__pagination {
          bottom: rem(0);

          @include xsmin {
            display: none; } } } } } }

.model-slide {
  $this: &;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  position: relative;
  height: auto;
  min-height: vw(684);

  padding: rem(52) rem(65) rem(66);

  color: $light;

  @include xl {
    padding: rem(40); }

  @include sm {
    padding: 0;
    color: inherit;
    text-align: center; }

  &__bg {
    @include coverdiv;
    @include cover;
    background-color: $light;
    z-index: 0;
    width: 100%;

    @include sm {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      position: static;
      min-height: vw(340, 414);
      margin-bottom: rem(30, $base-fz-sm); } }

  &__content,
  &__title-block, {
    position: relative;
    z-index: 1;
    @include sm {
      width: 100%; } }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }

  &__subttl {
    margin-top: auto;
    font-size: em(24);

    @include sm {
      font-size: em(20, $base-fz-sm); } }

  &__price-sm {
    font-size: em(30);
    font-weight: 300;
    @include sm {
      font-size: em(30, $base-fz-sm); } }

  &__price {
    margin-bottom: rem(35); }

  &__title-block {
    @include css-lock(62, 106, 413, 1399, font-size);
    font-weight: 600;
    line-height: 1;
    margin-bottom: rem(30);
    text-align: right;

    @include sm {
      display: none;
      color: $light;
      text-align: center; }

    @include xxxs {
      font-size: em(52, $base-fz-sm); }

    small {
      display: block;
      text-align: left;
      font-size: em(33, 106);
      font-weight: 300;
      @include sm {
        text-align: center; } }

    &--mob {
      @include smmin {
        display: none; }
      @include sm {
        display: block; } } }

  &__btn {
    margin-bottom: rem(25, $base-fz-sm);

    @include sm {
      .btn {
        background-color: $accent;
        border-color: $accent;
        color: $lighten;
        @include hover-focus {
          &:not(.btn--no-hover):not(.btn--disabled) {
            background-color: $accent-light;
            border-color: $accent-light; } } } } }

  &--left {
    align-items: flex-start;
    #{$this}__title-block {
        text-align: left;
        @include sm {
          text-align: inherit; } } } }
