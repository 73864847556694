.social {
  $this: &;

  display: flex;
  justify-content: center;
  text-align: center;
  font-size: em(16);

  &__item {
    margin-left: rem(50);
    margin-right: rem(50);
    &--hidden-sm {
      @include sm {
        display: none; } } }

  &__icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem(10);
    font-size: rem(24);
    opacity: .4;
    @include tr(opacity .3s); }

  &__link {
    display: block;
    @include hover-focus {
      #{$this}__icon {
        opacity: 1; } } }

  &--sm {
    #{$this}__item {
      margin-left: rem(25);
      margin-right: rem(25); } } }
