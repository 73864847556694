.hero-gallery {
  @include xsmin {
    @include clr;
    display: block; }

  &__btn {
    position: absolute;
    z-index: 1;
    bottom: vw(49);
    left: percentage((1920 - (514 * 2)) / 1920);
    transform: translate(calc(-100% - #{vw(34)}), 0);

    @include xs {
      display: none !important; } }

  &__item {
    float: left;
    display: block;
    font-size: 0;
    background-color: $light;

    width: percentage(514 / 1920);
    height: 50%;

    @include xs {
      float: none;
      height: auto; }

    @include before {
      @include coverdiv;
      background-image: linear-gradient(0deg, rgba(9, 27, 50, 0.7), rgba(9, 27, 50, 0.7));
      @include tr(opacity .4s, visibility .4s);
      @include hidden; }

    @include hover-focus {
      @include before {
        @include visible; } }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }

    &--lg {
      width: percentage((1920 - (514 * 2)) / 1920);
      height: 100%;
      @include xs {
        height: auto; } } } }

.section-gallery {
  @include xs {
    padding-bottom: 0 !important; }
  &__pagination {
    display: block !important;

    position: relative;
    left: auto !important;
    transform: none !important;
    bottom: auto !important;

    margin-left: auto;
    margin-right: auto;
    margin-top: rem(35); }

  &__item {
    font-size: 0; }

  &__wrap {
    @include md {
      margin-left: rem(-15);
      margin-right: rem(-15); }

    @include sm {
      margin-left: rem(-20, $base-fz-sm);
      margin-right: rem(-20, $base-fz-sm); }
    .slider {
      &__controls {
        top: calc(50% - #{rem(20)});

        @include xxl {
          display: flex; }

        @include r(1023) {
          display: none; } }

      &__prev,
      &__next {
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translate(0, -50%); }


      &__prev {
        left: rem(-35); }

      &__next {
          right: rem(-35);
          transform: translate(0, -50%) scaleX(-1); } } } }
