.article {
  $this: &;

  p, img, picture, ul, ol {
    @include notlast {
      margin-bottom: rem(30); } }

  h1, h2, h3, h4, h5, h6 {
    &:not(:last-child) {
      margin-bottom: rem(30); } }

  img, picture {
    max-height: 100%;
    &.is-align-left,
    &.is-align-right {
      max-width: calc(50% - #{rem(75 / 2)});
      @include xs {
        max-width: none;
        img {
          width: 100%; } } } }

  picture {
    img {
      margin-bottom: 0; } }

  h2 {
    &:not(.title) {
      @extend .title;
      @include css-lock(36, 64, 413, 1399, font-size); } }

  ul {
    &:not(.list):not(.nav__list) {
      list-style-type: disc;
      margin-left: 1rem; }
    &.list--bold {
      li {
        margin-bottom: 0; } } }

  ol {
    list-style-type: decimal;
    margin-left: 1rem; }

  li {
    margin-bottom: rem(8); }

  .btn {
    margin-top: rem(20); }

  .is-align-left {
    float: left;
    margin-right: rem(75) / 2; }

  .is-align-right {
    float: right;
    margin-left: rem(75) / 2; }

  .is-align-left,
  .is-align-right {
    display: block;
    @include xs {
      float: none;
      margin-left: 0;
      margin-right: 0;
      width: 100%; } }

  &__btn {
    display: block;
    margin-top: rem(30); }

  &__btns {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include sm {
      flex-direction: column;
      justify-content: center; } }

  &__inner {
    @include clr;

    max-width: rem(933);
    margin-left: auto;
    margin-right: auto;

    @include tr(max-height .7s);

    p, ul, ol {
      width: 50%;
      float: left;
      &:nth-child(odd) {
        padding-right: rem(75) / 2; }
      &:nth-child(even) {
        padding-left: rem(75) / 2; }

      @include sm {
        width: auto;
        float: none;
        &:nth-child(odd) {
          padding-right: 0; }
        &:nth-child(even) {
          padding-left: 0; } } }

    ul, ol {
      width: calc(50% - 1rem);
      @include sm {
        width: auto; } }

    &[data-animated-el="article"] {
      overflow: hidden;
      position: relative;
      @include before {
        @include coverdiv;
        z-index: 1;
        background-color: $white; }
      #{$this}__logo {
        opacity: 0; } }

    &--animate {
      --animation-delay: 0s;
      &::before {
        animation-name: slide-down;
        animation-duration: 1.5s;
        animation-fill-mode: forwards;
        animation-delay: var(--animation-delay, 0s); }
      #{$this}__logo {
        animation-name: fadeIn;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-delay: calc(var(--animation-delay) + 1.3s); } } }

  &__logo {
    display: block;
    text-align: center;
    float: left;
    width: 100%;

    margin-top: rem(35);
    @include sm {
      float: none; } }

  &--sm {
    p, ul, ol {
      &:nth-child(odd) {
        padding-right: rem(48) / 2; }
      &:nth-child(even) {
        padding-left: rem(48) / 2; }
      @include sm {
        &:nth-child(odd) {
          padding-right: 0; }
        &:nth-child(even) {
          padding-left: 0; } } }

    #{$this}__inner {
      max-width: rem(569); } }

  &--md {
    p, ul, ol {
      &:nth-child(odd) {
        padding-right: rem(48) / 2; }
      &:nth-child(even) {
        padding-left: rem(48) / 2; }
      @include sm {
        &:nth-child(odd) {
          padding-right: 0; }
        &:nth-child(even) {
          padding-left: 0; } } }


    #{$this}__inner {
      max-width: rem(626); } }

  &--lg {
    #{$this}__inner {
      max-width: rem(1130); } }

  &--flat {
    p, ul, ol {
      width: auto;
      float: none;

      &:nth-child(odd),
      &:nth-child(even) {
        padding: 0; } }

    &:not(#{$this}--flat-has-gaps) {
      p, ul, ol {
        margin-bottom: 0; } }

    #{$this}__inner {
      display: block; }

    #{$this}__logo {
      float: none; } }

  &--no-gaps {
    &:not(#{$this}--flat-has-gaps) {
      p, ul, ol {
        margin-bottom: 0; } } }

  &--full {
    #{$this}__inner {
      max-width: none; } }

  &--combine {
    p, ul, ol {
      &:nth-child(even),
      &:nth-child(odd) {
        padding: 0; } } }

  &--center {
    text-align: center; } }

@keyframes slide-down {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(100%); } }

