.range-slider {
  width: 100%;
  max-width: rem(320);
  .noUi-base {}

  .noUi-connects {
    border-radius: 0; }

  .noUi-connect {
    background-color: $white; }

  .noUi-handle {
    box-shadow: none;
    border-radius: 50%;
    background-color: $white;
    width: rem(20);
    height: rem(20);
    top: -9px;
    right: -10px;

    &::before,
    &::after {
      display: none; } }

  &__slider {
    border-radius: 0;
    border: 0;
    box-shadow: none;
    background-color: $accent-light;
    height: 2px;
    margin-bottom: rem(15); }

  &__value {
    background-color: transparent;
    color: $white;
    width: rem(100);
    &[data-type="max"] {
      text-align: right; } }

  &__inputs,
  &__values {
    display: flex;
    align-items: center;
    justify-content: space-between; }

  &__values {
    margin-bottom: rem(15); } }
