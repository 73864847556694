.nav {
  $this: &;
  $link-line-width: 167px;
  @mixin current-link-xl {
    position: relative;
    display: inline-flex;
    align-items: center;
    @include before {
      display: block;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: calc(100% + 30px);
      height: 1px;
      background-color: rgba($accent, .2);
      @include tr(width .3s);
      width: 0;

      @include dark-theme {
        background-color: rgba($white, .2); } } }

  font-size: em(16);

  @include xl {
    font-size: em(32);
    font-weight: 600;
    text-transform: uppercase; }

  @include sm {
    font-size: em(32, $base-fz-sm); }
  @include xxxs {
    font-size: em(24, $base-fz-sm); }
  @include r(374) {
    font-size: em(18, $base-fz-sm); }

  &__list {
    display: flex;
    align-items: center;


    @include xl {
      display: block; } }

  &__item {
    margin-left: rem(30);
    margin-right: rem(30);

    position: relative;

    @include xl {
      margin-bottom: rem(30);
      margin-left: 0;
      margin-right: 0; }

    &--current {
      text-decoration: underline;
      @include xl {
        text-decoration: none;
        #{$this}__link {
          @include current-link-xl;
          @include before {
            width: $link-line-width; } } } }

    &--has-dropdown {
      cursor: pointer;

      @include xlmin {
        &:hover,
        &:focus {
          #{$this}__sublist {
            @include visible; }
          #{$this}__link {
            @include after {
              transform: rotate(135deg);
              top: .2em; } } } }

      @include hover-focus {
        #{$this}__sublist {
          @include visible; }
        #{$this}__link {
          @include after {
            transform: rotate(135deg);
            top: .2em; } } }

      > #{$this}__link {
        display: inline-flex;
        align-items: center;
        @include hover-focus {
          text-decoration: none;
          @include before {
            display: none; } }

        @include after {
          @include icon-arrow(.5, .5, .1, currentColor, -45, em);
          display: block;
          position: relative;
          margin-left: .5em;
          margin-top: -.1em;
          @include tr(transform .3s, top .3s); } } }

    &--active {
      > #{$this}__link {
        margin-bottom: rem(15);
        @include after {
          transform: rotate(135deg);
          top: .2em; } } } }

  &__link {
    @include xl {
      white-space: nowrap; } }

  &__link,
  &__sublink {
    @include tr(color .3s);
    @include hover-focus {
      @extend #{$this}__item--current; }
    @include xl {
      @include current-link-xl;
      @include hover-focus {
        @include before {
          width: $link-line-width; } } } }

  &__sublist {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    width: rem(300);

    background-color: $white;
    padding: rem(20);

    @include dark-theme {
      background-color: $black; }

    @include ready {
      @include tr(opacity .3s, visibility .3s); }

    @include xlmin {
      @include hidden; }

    @include xl {
      background-color: transparent;
      padding: 0;
      margin-left: rem(-35);
      margin-right: rem(-35);

      position: relative;
      top: auto;
      width: auto;
      max-height: 0;
      overflow: hidden;

      font-size: em(24, 32);
      font-weight: 300;

      @include sm {
        margin-left: rem(-35, $base-fz-sm);
        margin-right: rem(-35, $base-fz-sm); }
      @include xxxs {
        margin-left: rem(-20, $base-fz-sm);
        margin-right: rem(-20, $base-fz-sm); }

      @include ready {
        @include tr(max-height .4s $easeOutQuad, padding .4s $easeOutQuad); }

      &--open {
        max-height: 300px; } } }

  &__subitem {
    padding-top: rem(5);
    padding-bottom: rem(5);
    @include xl {
      padding: 0; } }

  &__sublink {
    @include xl {
      display: block;
      padding: rem(8) rem(35);
      margin-bottom: rem(3);
      background-color: #EAEAEA; }

    @include sm {
      padding: rem(8, $base-fz-sm) rem(35, $base-fz-sm); }

    @include xxxs {
      padding: rem(8, $base-fz-sm) rem(20, $base-fz-sm); } }

  &--footer {
    @include xl {
      font-size: em(16);
      font-weight: inherit;
      text-transform: none; }

    @include sm {
      font-size: em(16, $base-fz); }
    @include xxxs {
      font-size: em(16, $base-fz-sm); }

    #{$this}__list {
      flex-wrap: wrap;
      @include xl {
        display: flex; }

      @include sm {
        display: block; } }

    #{$this}__item {
      width: 50%;
      margin-left: 0;
      margin-right: 0;
      padding-right: rem(10);
      margin-bottom: rem(25);
      @include xl {
        margin-bottom: rem(25); }
      @include sm {
        margin-bottom: rem(20, $base-fz); }
      &--current {
        text-decoration: underline; } }

    #{$this}__link {
      @include hover-focus {
        @include before {
          display: none; } } } }

  &--footer-social {
    #{$this}__list {
      display: block; }
    #{$this}__item {
      width: 100%;
      padding-right: 0;
      white-space: nowrap; } }

  &--lg {
    font-size: em(24);

    @include xl {
      font-size: em(24);
      text-transform: none;
      font-weight: inherit; }

    #{$this}__list {
      @include xl {
        display: flex; } }
    #{$this}__item {
      margin-left: rem(40);
      margin-right: rem(40); } }

  &--section {
    font-weight: 600;

    @include xl {
      font-size: 1em;
      text-transform: inherit; }

    #{$this}__link {
      display: inline-flex;
      align-items: center;

      @include after {
        @include icon-arrow(.6, .6, .1, currentColor, 225, em);
        display: block;
        margin-left: 1em;
        position: relative;
        top: .1em; } }

    #{$this}__list {
      display: block; }

    #{$this}__item {
      margin: 0 0 rem(10); } } }

.nav-lg {
  $this: &;

  font-size: em(24);

  &__item {
    @include notlast {
      margin-bottom: rem(10); } }

  &__link {
    @include hover-focus {
      text-decoration: underline; } }

  &--col-2,
  &--col-4 {
    #{$this}__list {
      @extend %row; }
    #{$this}__item {
      padding-right: rem(10);
      @include xxs {
        padding-right: 0; } } }


  &--col-2 {
    #{$this}__item {
      width: 50%;
      @include xxs {
        width: 100%; } } }

  &--col-4 {
    #{$this}__list {
      justify-content: space-between; }

    #{$this}__item {
      width: 25%;
      @include md {
        width: 50%;
        text-align: center; }

      @include xxs {
        width: 100%; } } } }



