.faq {
  &__item {
    @include notfirst {
      border-top: 1px solid $light; } } }

.question {
  $this: &;

  &__title {
    display: block;
    width: 100%;
    text-align: left;

    font-size: em(25);
    font-weight: 600;
    padding: rem(29) rem(30) rem(29) 0;

    position: relative;

    @include sm {
      font-size: em(25, $base-fz-sm); }

    &--active {
      #{$this}__icon {
        &::after {
          display: none; } } } }

  &__icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);

    width: rem(18);
    height: rem(18);

    &::before,
    &::after {
      content: '';
      @include vcenter;
      background-color: currentColor; }

    &::before {
      width: 100%;
      height: 1px; }

    &::after {
      height: 100%;
      width: 1px; } }

  &__content-block {
    margin-bottom: rem(30); }

  &__content {
    max-height: 0;
    overflow: hidden;
    @include tr(max-height .5s $easeOutQuad); } }
