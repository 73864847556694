.hero {
  $this: &;

  position: relative;
  color: $white;

  &__bg {
    @include coverdiv;
    @include cover;
    background-color: $light;
    z-index: 0;

    @include dark-theme {
      background-color: $black; } }

  &__block {
    position: relative;
    z-index: 1; }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;
    z-index: 1;
    overflow: hidden;

    min-height: vw(627);
    padding: rem(27) rem(19) rem(60);

    > .container {
      &:not(.hero__breadcrumbs) {
        width: 100%;
        padding-left: 0;
        padding-right: 0; } }

    @include sm {
      min-height: vw(400, 767); }

    @include xs {
      justify-content: flex-end; }

    @include xxs {
      min-height: vw(371, 414); } }

  &__breadcrumbs {
    @extend .container;
    @extend .container--md;
    position: absolute;
    z-index: 1;
    width: 100%;
    bottom: rem(27);
    left: 50%;
    transform: translate(-50%, 0); }

  &__title {
    text-align: center;
    width: 100%;
    max-width: percentage(850 / 1920);
    margin-top: -10%;
    @include sm {
      max-width: none; }
    &--full {
      max-width: percentage(1390 / 1920);
      margin-left: auto;
      margin-right: auto;
      @include lg {
        max-width: none; } } }

  &__subttl {
    font-size: em(24);
    font-weight: 400;
    line-height: 1;
    @include xs {
      font-size: em(18, $base-fz-sm); } }

  &__gallery {
    @include coverdiv;
    z-index: 0;

    @include xs {
      margin-bottom: rem(-25, $base-fz-sm); }

    &.slider__wrap {
      position: absolute; } }

  &__scroll-down {
    position: absolute;
    z-index: 1;
    bottom: rem(20);
    left: 50%;
    transform: translate(-50%, 0);

    @include rh(700) {
      bottom: rem(10); }

    @include mdmin {
      display: none; } }

  &__form {
    @include xlmin {
      height: $search-form-height; } }

  &--right {
    #{$this}__title {
      margin-left: auto; } }

  &--v-center {
    #{$this}__title {
      margin-top: 0; } }

  &--lg {
    display: flex;
    flex-direction: column;

    @include xlmin {
      @include windowHeight(#{$header-height}); }

    #{$this}__slider {
      flex-grow: 1;

      @include md-port {
        @include windowHeight($header-height); }

      @include md-land {
        height: auto; } } }

  &--article {
    #{$this}__title {
      @extend .container--md;

      padding-left: 0;
      padding-right: 0;
      margin-left: auto;
      margin-right: auto;

      margin-top: 0;
      text-align: left; }


    #{$this}__inner {
      justify-content: flex-start;
      padding-top: vw(50);
      padding-bottom: vw(165);

      @include xs {
        min-height: vw(371, 414);
        padding-top: rem(30, $base-fz-sm);
        padding-bottom: rem(50, $base-fz-sm); } } }

  &--gallery {
    min-height: vw(607);
    @include xs {
      min-height: vw(340, 414); } }

  &--about {
    justify-content: center;
    #{$this}__title {
      margin-top: 0; } }

  &--title-simple {
    #{$this}__title,
    #{$this}__subttl {
      @extend .container;
      @extend .container--md;
      width: 100%;
      text-align: left; }

    #{$this}__title {
      @include xs {
        margin-top: 10%; } }

    #{$this}__subttl {
      margin-bottom: 10%;
      @include xs {
        margin-bottom: 0; } }

    #{$this}__inner {
      @include xs {
        justify-content: flex-start; } } } }

.main-hero-block {
  display: flex;
  padding: rem(20) rem(55);
  text-align: center;

  @include lg {
    padding: rem(15) rem(20); }

  @include md {
    padding: rem(10); }

  @include sm {
    display: block;

    background-color: $light;
    backdrop-filter: none;
    color: $c-text;

    padding: rem(65, $base-fz-sm) rem(20, $base-fz-sm) rem(75, $base-fz-sm); }

  @include xxxs {
    padding: rem(40, $base-fz-sm) rem(20, $base-fz-sm) rem(50, $base-fz-sm); }

  &__title,
  &__subttl {
    display: flex;
    align-items: center;
    justify-content: center;
    @include sm {
      width: auto;
      display: block;
      padding: 0; } }

  &__title {
    @include css-lock(56, 64, 413, 1919, font-size);
    padding-right: rem(35);
    @include md {
      padding-right: rem(10); }
    @include xxxs {
      font-size: em(48, $base-fz-sm); } }

  &__subttl {
    font-size: em(32);
    padding-left: rem(35);

    border-left: 1px solid #F2F1F0;

    @include md {
      padding-left: rem(10);
      font-size: em(24); }

    @include sm {
      font-size: em(24, $base-fz-sm);
      margin-bottom: rem(55, $base-fz-sm); } }

  &__scroll-down {
    @include smmin {
      display: none; } } }

.hero-slide {
  position: relative;
  background-color: $light;

  &__bg {
    display: block;
    font-size: 0;

    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 71%;
    height: 100%;
    z-index: 0;

    @include md {
      position: static;
      width: 100%; }

    @include md-port {
      height: 50%; }
    @include md-land {
      @include windowHeight($header-height); }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__content {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 30%;

    @include xl {
      width: 35%; }

    @include md {
      width: 100%; } }

  &__inner {
    @include mdmin {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: 100%; }

    @include md-port {
      height: 50%; }

    @include md-land {
      height: auto; } } }

.hero-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  background-color: $accent;
  padding: rem(40);

  @include xl {
    padding: rem(30); }

  @include md {
    padding-bottom: rem(80); }

  @include rh(700) {
    padding-bottom: rem(50);
    padding-top: rem(10); }

  &__title-block,
  &__price-block,
  &__btn {
    width: 100%;
    @include md {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto; }

    @include xs {
      max-width: rem(300); } }

  &__title-block {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    @include notlast {
      margin-bottom: rem(10); } }

  &__title {
    @extend .title;
    @extend .title--h2;

    font-weight: inherit;
    text-transform: none;
    margin-right: rem(15);

    @include xs {
      font-size: em(26); }

    @include rh(700) {
      font-size: em(21); } }

  &__subttl {
    @extend .title;
    @extend .title--h1;
    text-transform: none;
    line-height: 1;

    @include xs {
      font-size: em(40);
      line-height: 1; }

    @include rh(700) {
      font-size: em(34); } }

  &__price-block {
    margin-top: auto;
    margin-bottom: rem(30);

    @include xs {
      margin-bottom: rem(15, $base-fz-sm); } }

  &__price-block-title {
    @extend .title;
    @extend .title--h1-xxs;

    @include css-lock(21, 28, 413, 1399, font-size);
    font-weight: 600;
    text-transform: uppercase;

    @include xs {
      font-size: 1em; }

    @include rh(700) {
      font-size: 0.8em; } }

  &__price {
    &.price {
      @extend .title;
      @extend .title--h1;
      @include xs {
        font-size: em(40); }

      @include rh(700) {
        font-size: em(34); } } }

  &__subtext {
    @extend .title;
    @extend .title--h1-xxs;

    text-transform: uppercase;
    color: $danger;

    @include xs {
      font-size: em(30); }

    @include rh(700) {
      font-size: em(26); } } }
