.overflow-hidden {
  overflow: hidden; }
.pt-0 {
  padding-top: 0 !important; }
.pb-0 {
  padding-bottom: 0 !important; }

.flex-column-reverse {
  &--xs {
    @include xs {
      display: flex;
      flex-direction: column-reverse;
      > div, > a, > picture, > img, > p {
        &:last-child {
          margin-bottom: rem(50, $base-fz-sm); }
        &:first-child {
          margin-bottom: 0; } } } } }

.hide {
  display: none; }

.flex {
  display: flex !important; }

.justify-content-space-between {
  justify-content: space-between; }

.align-items-center {
  align-items: center; }
