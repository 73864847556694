.form {
  $this: &;

  position: relative;
  &__field {
    @include notlast {
      margin-bottom: rem(16); } }

  &__btn {
    margin-top: rem(38);
    text-align: center; }

  &__success {
    @include coverdiv;
    @include hidden;
    @include ready {
      @include tr(opacity .5s, visibility .5s); } }

  &__text {
    text-align: center; }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -rem(20);
    margin-right: -rem(20);

    @include sm {
      margin-left: rem(-10);
      margin-right: rem(-10); } }

  &__thumbs {
    display: flex;
    flex-wrap: wrap;
    margin-left: rem(-5);
    margin-right: rem(-5);

    > .file-input {
      margin-left: rem(5);
      margin-right: rem(5); } }

  &__col {
    text-align: center;
    width: 50%;
    padding-left: rem(20);
    padding-right: rem(20);
    margin-bottom: rem(40);
    @include sm {
      padding-left: rem(10);
      padding-right: rem(10);
      margin-bottom: rem(20); }

    &--full {
      width: 100%; }

    @include xs {
      width: 100%; } }

  &--success {
    #{$this}__form {
      @include hidden; }
    #{$this}__success {
      @include visible; } }

  &--simple {
    #{$this}__title {
      text-transform: uppercase;
      font-size: em(25);
      margin-bottom: rem(50);
      text-align: center;

      @include sm {
        font-size: em(20); } }

    #{$this}__btn {
      margin-top: rem(55); } } }



.form-success {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  background-color: $accent;
  color: $lighten;

  padding: rem(30);

  &__body {
    padding: rem(15) 0; }

  &__title {
    @extend .title;
    @extend .title--h3;
    text-transform: uppercase;
    font-weight: 600; }

  &__subttl {
    @extend .title;
    @extend .title--h4;
    text-transform: uppercase;
    font-weight: 600; }

  &__btn {
    font-size: em(14);
    padding-bottom: .4em;
    display: inline-block;
    border-bottom: 1px solid currentColor; } }

