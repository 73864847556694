.out {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: $header-height;

  &__header {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    height: $header-height; }

  &__main {
    flex-grow: 1; }

  &__footer {} }

