.fadeIn {
  animation-name: fadeIn; }

.fade-in-up {
  animation-name: fade-in-up; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }


@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(20px); }

  100% {
    opacity: 1;
    transform: translateY(0); } }

