.popup {
  $this: &;

  @extend %overlay;
  z-index: 15;
  background-color: rgba($black, .7);

  overflow: auto;
  padding: rem(15);

  @include vertical;
  @include hidden;
  @include ready {
    @include tr(opacity .4s, visibility .4s); }

  &__inner {
    display: inline-block;
    vertical-align: middle;
    font-size: 1rem;
    text-align: left;
    width: 100%;
    max-width: rem(1024);
    background-color: $light;
    position: relative;

    padding: rem(130) rem(30) rem(100);

    @include lg {
      padding: rem(80) rem(20); } }

  &__title,
  &__subttl {
    max-width: rem(500);
    margin-left: auto;
    margin-right: auto; }

  &__title {
    margin-bottom: rem(10);
    text-align: center; }

  &__subttl {
    margin-bottom: rem(60);
    @include lg {
      margin-bottom: rem(30); } }


  &__form {
    max-width: rem(450);
    margin-left: auto;
    margin-right: auto; }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    z-index: 1;
    top: rem(30);
    right: rem(60);
    background-color: $accent;

    width: rem(55);
    height: rem(55);

    @include tr(background-color .3s);

    @include lg {
      top: rem(20);
      right: rem(20); }

    @include hover-focus {
      background-color: $accent-light; }

    .close {
      position: relative;
      left: -.1em; } }

  &__img {
    margin-bottom: rem(20);
    &, img {
      width: 100%;
      display: block;
      height: vw(590);
      object-fit: cover;
      background-color: $light;

      @include lg {
        height: vw(700); }
      @include md {
        height: vw(900); } } }

  &--thanks {
    #{$this}__text {
      font-size: em(25);
      margin-left: auto;
      margin-right: auto;
      max-width: rem(845); }

    #{$this}__inner {
      padding: rem(25) rem(25) rem(50);
      @include lg {
        padding: rem(20) rem(20) rem(30); } } }

  &--active {
    @include visible; } }

