.breadcrumbs {
  letter-spacing: .1em;

  @include sm {
    letter-spacing: normal; }
  @include xs {
    font-size: em(14, $base-fz-sm); }

  &__item {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;

    @include notlast {
      @include after {
        @include icon-arrow(.7, .7, .15, currentColor, -135, em);
        display: block;
        margin-left: rem(10);
        margin-right: rem(10);

        @include xs {
          margin-left: rem(2, $base-fz-sm);
          margin-right: rem(2, $base-fz-sm); } } }

    &--current {
      font-weight: 600; } }

  &__link {
    @include hover-focus {
      text-decoration: underline; } } }
