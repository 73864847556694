@include smmin {
  %first-child-width {
    width: percentage(840 / 1380); }
  %second-child-width {
    width: percentage((1380 - 840) / 1380); }
  %third-child-width {
    width: 100%; }
  %forth-child-width {
    width: percentage(491 / 1380); }
  %fifth-child-width {
    width: percentage((1380 - 491) / 1380); } }

.posts {
  $grid-gap: rem(3);
  @extend %row;

  margin-left: $grid-gap / -2;
  margin-right: $grid-gap / -2;

  &__item {
    width: 100%;
    min-height: vw(446);

    padding-left: $grid-gap / 2;
    padding-right: $grid-gap / 2;
    margin-bottom: $grid-gap;

    @include smmin {
      @for $i from 1 through 200 {
        $is-first: (($i + 4) % 5) == 0;
        $is-second: (($i + 3) % 5) == 0;
        $is-third: (($i + 2) % 5) == 0;
        $is-forth: (($i + 1) % 5) == 0;
        $is-fifth: $i % 5 == 0;

        @if $is-first {
          &:nth-child(#{$i}) {
            @extend %first-child-width; } }

        @if $is-second {
          &:nth-child(#{$i}) {
            @extend %second-child-width; } }

        @if $is-third {
          &:nth-child(#{$i}) {
            @extend %third-child-width; } }

        @if $is-forth {
          &:nth-child(#{$i}) {
            @extend %forth-child-width; } }

        @if $is-fifth {
          &:nth-child(#{$i}) {
            @extend %fifth-child-width; } } } }

    &:not(.hide) {
      animation-name: fade-in-up;
      animation-duration: 1s; } } }

.post-card {
  $this: &;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  position: relative;
  padding: vw(186) rem(35) rem(25);
  overflow: hidden;

  color: $lighten;
  height: 100%;
  background-color: $light;

  @include xl {
    padding: vw(186) rem(20) rem(20); }

  @include sm {
    min-height: vw(340, 414); }

  @include hover-focus {
    @include before {
      @include visible; }
    #{$this}__img {
      transform: scale(1.1); } }

  @include before {
    @include coverdiv;
    z-index: 1;
    background-color: rgba($black, .7);
    @include tr(opacity .4s, visibility .4s);
    @include hidden; }

  &__img {
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include tr(transform .4s $easeOutQuad); }

  &__content {
    position: relative;
    z-index: 2; }

  &__title {
    display: block;
    font-weight: 600;
    font-size: em(24);
    margin-bottom: rem(5); }

  &__date {
    display: block;
    margin-bottom: rem(5); }

  &__text {
    display: block;
    max-height: 2.8em;
    max-width: rem(470);
    overflow: hidden;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; } }
