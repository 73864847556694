.car-meta {
  overflow: hidden;
  &__location {
    max-width: rem(580);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem(49);
    @include xs {
      margin-bottom: rem(38, $base-fz-sm); }
    @include xxxs {
      margin-bottom: rem(20, $base-fz-sm); } }

  &__price-block {
    max-width: rem(684);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem(96);
    @include xs {
      margin-bottom: rem(80, $base-fz-sm); }
    @include xxxs {
      margin-bottom: rem(50, $base-fz-sm); }
    .price-block {
      @include xs {
        margin-bottom: rem(20, $base-fz-sm); } }

    &--center {
      margin-left: auto;
      margin-right: auto;
      max-width: 33.33333%;
      @include xl {
        max-width: rem(400); }
      @include xs {
        max-width: none; } } }

  &__price-sm {
    margin-top: rem(17);
    font-size: em(25);
    text-align: center;
    font-weight: 600;
    small {
      font-size: em(18, 25);
      font-weight: 300; } }

  &__info {
    @include md {
      position: relative;
      left: 7%; }
    @include sm {
      max-width: 60%;
      margin-left: auto;
      margin-right: auto;
      left: auto; }
    @include xxxs {
      max-width: none; } } }

.order-block {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: $light;
  padding: rem(14) rem(33);

  @include xs {
    padding: 0;
    background-color: transparent; }

  &--price {
    @include xs {
      display: block;
      text-align: center;
      background-color: $light;
      padding: rem(14) rem(33); }

    @include xxxs {
      padding: rem(14); } }

  &--price-full {
    display: block;
    padding: rem(16) 0 0;

    @include xs {
      background-color: $light; }

    .price-full-list {
      margin-bottom: rem(18);
      left: rem(20);
      position: relative;

      @include xl {
        left: auto;
        max-width: rem(500);
        margin-left: auto;
        margin-right: auto; } } } }


.location {
  display: flex;
  align-items: center;
  &__icon {
    font-size: 1.2em;
    margin-right: .5em; } }

.price-block {
  @include xs {
    display: flex;
    align-items: flex-end;
    justify-content: center; }
  &__title {
    @include xs {
      margin-right: .5em;
      margin-bottom: .3em; } }
  &__price {
    font-size: em(58);
    line-height: 1;
    font-weight: 600;
    @include sm {
      font-size: em(58, $base-fz-sm); }
    @include xxxs {
      font-size: em(46, $base-fz-sm); } } }

.price-full-list {
  &__item {
    display: flex;
    align-items: flex-end; }

  &__title {
    text-align: right;
    width: 30%;
    padding-right: rem(16);
    white-space: nowrap;
    display: block;
    margin-bottom: .3em;
    @include xl {
      width: 40%; } }

  &__text {} }


.car-meta-info {
  @extend %row;

  @include lg {
    font-size: em(16); }
  @include sm {
    font-size: 1em; }

  &__item {
    $gap: vw(80);
    --gap: #{$gap};
    width: percentage(1 / 3);
    text-align: center;

    @include lg {
      --gap: #{vw(40)}; }

    @include sm {
      width: 100% !important;
      padding: 0 !important; }

    @include notlast {
      width: percentage(1 / 3);
      border-right: 1px solid currentColor;
      @include sm {
        border-right: 0; } }

    .car-meta-list {
      display: inline-flex;
      text-align: left;
      @include sm {
        display: flex; } }

    &:nth-child(1) {
      @include md {
        width: 27%; } }
    &:nth-child(2) {
      text-align: center;
      @include md {
        width: 27%; }
      .car-meta-list {
        max-width: calc(100% - (var(--gap) * 2));
        @include rmin(2500) {
          max-width: none; }
        @include sm {
          max-width: none; } } }
    &:nth-child(3) {
      @include md {
        width: 46%; } } } }

.car-meta-list {
  @extend %row;

  &__title,
  &__text {
    width: 50%;
    margin-bottom: rem(10); } }
