.model-cards {
  $this: &;

  @extend %row;

  .empty {
    @extend .title;
    @extend .title--h2;

    margin-left: auto;
    margin-right: auto; }

  &__item {
    width: 25%;
    position: relative;

    @include lg {
      width: 50%; }

    @include xs {
      width: 100%; } }

  &--color {
    #{$this}__item {
      @include lgmin {
        &:nth-child(-2n+4) {
          .model-card {
            background-color: $light; } }

        $start-first-index: 0;
        $start-second-index: 4;

        @for $i from 0 through 200 {
          $start-first-index: if($i == 0, 0, $start-first-index + 4);
          $first-index: $start-first-index + 4;

          $start-second-index: if($i % 2 == 0, $start-second-index + 3, $start-second-index + 5);

          &:nth-child(#{$first-index}) ~ #{$this}__item:nth-child(-2n + #{$start-second-index}) {
            .model-card {
              background-color: $light; } } }


        // &:nth-child(4) ~ #{$this}__item:nth-child(-2n+7), // +3
        // &:nth-child(8) ~ #{$this}__item:nth-child(-2n+12), // +5
        // &:nth-child(12) ~ #{$this}__item:nth-child(-2n+15), // +3
        // &:nth-child(16) ~ #{$this}__item:nth-child(-2n+20) // +5
        //   .model-card
 } } } }        //     background-color: $light

.model-card {
  $this: &;

  text-align: center;
  position: relative;
  height: 100%;

  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: rem(30) rem(10); }

  &__title {
    margin-bottom: rem(15);
    font-weight: 600; }

  &__img {
    margin-bottom: rem(25);
    position: relative;
    padding-top: 50%;
    margin-top: auto;

    img {
      @include vcenter;
      max-height: 90%;
      max-width: 90%;
      pointer-events: none; } }

  &__price {
    margin-bottom: rem(5); }

  &__price-panel {
    margin-bottom: rem(12);
    margin-left: rem(-10);
    margin-right: rem(-10); }

  &__price-block {
    margin-bottom: rem(12); }

  &__btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: rem(306); }

  &__list {
    margin-bottom: rem(15); } }


.model-list {
  @extend %row;
  // max-width: rem(195)
  margin-left: auto;
  margin-right: auto;

  &__title,
  &__text {
    width: 50%;
    // padding-bottom: rem(5)
    // margin-bottom: rem(5)
    text-transform: uppercase;

    &--sm {
      text-transform: none;
      margin-top: rem(6);
      font-size: .9em; } }



  &__title {
    text-align: right;
    position: relative; }

  &__text {
    padding-left: .3em;
    text-align: left;
    position: relative; } }

.price-panel {
  $this: &;

  display: flex;
  color: $light;
  font-size: em(16);

  strong {
    font-size: 1em; }

  &__item {
    width: percentage(1 / 3);
    background-color: $danger-dark;
    padding: rem(6);
    position: relative;

    &:not(:first-child):not(#{$this}__item--accent) {
      @include before {
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);

        width: 1px;
        height: percentage(21 / 32);
        background-color: currentColor; } }


    &--accent {
      background-color: $accent; } } }
