.filters {
  &__block {
    &--sm {
      background-color: $light;
      padding: rem(5) 0; }

    &--lg {
      display: flex;
      flex-direction: column;
      justify-content: center;

      background-color: $accent;
      padding: rem(5) 0 0;
      min-height: rem(120); } }

  &__inner {
    @extend %row;
    @include sm {
      flex-wrap: nowrap;
      overflow: auto;
      padding-bottom: rem(10, $base-fz-sm);
      .simplebar-content {
        display: flex; } }

    .simplebar-content {
        display: flex;
        flex-wrap: wrap;
        @include sm {
          flex-wrap: nowrap; } } }

  &__btn {
    margin-right: rem(20);
    margin-bottom: rem(5);
    flex-shrink: 0; }

  &__tab {
    flex-shrink: 0;
    white-space: nowrap;
    @include notlast {
      margin-right: rem(20); }
    @include hover {
      text-decoration: underline; }

    &[data-state="active"],
    &--active {
      font-weight: 700; } }

  &__tabs-item {
    overflow: hidden !important;
    padding: 0;
    max-height: 0;

    &[data-state="active"],
    &--active {
      overflow: visible !important;
      max-height: none;
      &.scrollbar {
        overflow: auto !important; } } }

  &__controls {
    display: flex;
    align-items: center;

    > .input,
    > .btn {
      @include notlast {
        margin-right: rem(30); } } } }

.filter-btn {
  $this: &;

  position: relative;
  color: $white;
  letter-spacing: .01em;

  cursor: pointer;

  input {
    position: absolute;
    @include hidden;

    &:checked + #{$this}__title {
      color: $c-text;
      background-color: $white;
      border-color: $white;
      sup {
        color: $accent; } } }

  &__title {
    display: block;
    padding: em(6.5) em(14);
    border-radius: rem(30);
    border: 1px solid transparent;
    sup {
      display: inline-block;
      font-size: 10px;
      opacity: 0.5;
      color: $white;
      vertical-align: top;
      position: relative;
      top: -.7em;
      left: -.2em; } }

  &--plain {
    color: inherit;
    letter-spacing: inherit;

    input {
      &:checked + #{$this}__title {
        color: inherit;
        background-color: transparent;
        border-color: transparent;
        font-weight: 700; } }

    #{$this}__title {
      padding: 0; } }

  &--dark {
    color: $c-text;
    font-size: em(16);
    input {
      &:checked + #{$this}__title {
        color: $white;
        background-color: $accent;
        border-color: $accent;
        sup {
          color: $white; } } }
    #{$this}__title {
      sup {
        color: $accent; } } } }

