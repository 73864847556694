.footer {
  background-color: $dark;
  color: $white;
  position: relative;

  @include dark-theme {
    background-color: $black; }

  &__inner {
    @extend %row;
    flex-wrap: nowrap;

    max-width: rem(681);
    margin-left: auto;
    margin-right: auto;

    // +lg
    //// max-width: none

    @include sm {
      display: block; } }

  &__top {
    padding: rem(45) rem(20) rem(45 - 25); } // minus navigation margin

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: rem(25) rem(20);
    border-top: 1px solid rgba($white, .2); }

  &__nav {
    flex-grow: 1;
    @include sm {
      margin-bottom: rem(58, $base-fz-sm); } }

  &__social {}


  &__links {
    font-size: em(13);
    a {
      @include hover-focus {
        text-decoration: underline; } } }

  &__auth {
    display: inline-block;
    font-size: 0;
    img {
      width: 80px; } }

  &__scroll-to-top {
    position: absolute;
    z-index: 1;
    top: rem(63);
    right: rem(20);

    @include smmin {
      display: none; } } }
