@mixin lg-port {
  @media (orientation: portrait) and (max-width: '1199px') {
    @content; } }

@mixin lg-land {
  @media (orientation: landscape) and (max-width: '1199px') {
    @content; } }

@mixin md-port {
  @media (orientation: portrait) and (max-width: '991px') {
    @content; } }

@mixin md-land {
  @media (orientation: landscape) and (max-width: '991px') {
    @content; } }

@mixin xxxl {
  @include r(1919) {
    @content; } }
@mixin xxl {
  @include r(1499) {
    @content; } }
@mixin xl {
  @include r(1399) {
    @content; } }
@mixin lg {
  @include r(1199) {
    @content; } }
@mixin md {
  @include r(991) {
    @content; } }
@mixin sm {
  @include r(767) {
    @content; } }
@mixin xs {
  @include r(575) {
    @content; } }
@mixin xxs {
  @include r(479) {
    @content; } }
@mixin xxxs {
  @include r(413) {
    @content; } }

@mixin xxxlmin {
  @include rmin(1920) {
    @content; } }
@mixin xxlmin {
  @include rmin(1500) {
    @content; } }
@mixin xlmin {
  @include rmin(1400) {
    @content; } }
@mixin lgmin {
  @include rmin(1200) {
    @content; } }
@mixin mdmin {
  @include rmin(992) {
    @content; } }
@mixin smmin {
  @include rmin(768) {
    @content; } }
@mixin xsmin {
  @include rmin(576) {
    @content; } }
@mixin xxsmin {
  @include rmin(480) {
    @content; } }
@mixin xxxsmin {
  @include rmin(414) {
    @content; } }
